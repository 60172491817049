import { default as originalAxios } from 'axios'
import app from '@/progress'

export const axiosOnce = originalAxios.create({
  //const API_URL = process.env.API_URL || 'https://localhost:3000';

  // `baseURL` is prepended to URLs passed to axios
  //axios.defaults.baseURL = API_URL;
})

// POST with JSON content type
axiosOnce.defaults.headers.post['Content-Type'] = 'application/json'

// `xsrfCookieName` is the name of the cookie
// to use as a value for xsrf token.
// Default is 'XSRF-TOKEN'
axiosOnce.defaults.xsrfCookieName = 'CSRF-TOKEN'

// `xsrfHeaderName` is the name of the http header
// that carries the xsrf token value.
// Default is 'X-XSRF-TOKEN'
axiosOnce.defaults.xsrfHeaderName = 'X-CSRF-Token'

// `withCredentials` indicates whether or not cross-site
// Access-Control requests should be made using credentials.
axiosOnce.defaults.withCredentials = true


// Progress bar
axiosOnce.interceptors.request.use(config => {
  app.$Progress.start();
  return config;
});

axiosOnce.interceptors.response.use(response => {
  app.$Progress.finish();
  return response;
});

// Only one request method
let call


// ! Abort duplicate request
const pending = {}
const CancelToken = originalAxios.CancelToken
const removePending = (config, f) => {
  if (config == null) {
    return
  }
  // make sure the url is same for both request and response
  const url = config.url.replace(config.baseURL, '/')
  // stringify whole RESTful request with URL params
  const flagUrl = url + '&' +
    config.method + '&' +
    JSON.stringify(config.params)

  if (config.method == 'get') {
    return
  }
  // Fix double login request booking process
  if (url == '/api/login') {
    return
  }
  if (flagUrl in pending) {
    if (f) {
      f() // abort the request
    } else {
      delete pending[flagUrl]
    }
  } else {
    if (f) {
      pending[flagUrl] = f // store the cancel function
    }
  }
}

// Importing store dependency dynamically at run time instead of at compile time to prevent circular dependency issue.
async function dynamicImport() {
  const { default: store } = await import('@/clients/store');
  return store;
}

// Intercept all 401 Unauthorized responses before
// they are handled by `then` or `catch`

const requestInterceptor = async () => {
  const store = await dynamicImport();
  axiosOnce.interceptors.request.use((config) => {
    store.commit('START_INDICATOR')

    config.cancelToken = new CancelToken((c) => {
      removePending(config, c)
    })
  
    return config;
  },
  (error) => {
    store.commit('STOP_INDICATOR')
    alert(error)
    // Do something with request error
    return Promise.reject(error)
  })
}

const responseInterceptor = async () => {
  const store = await dynamicImport();

  axiosOnce.interceptors.response.use(
    (response) => {
      removePending(response.config)
      store.commit('STOP_INDICATOR')
      return response;
    },
    (error) => {
      removePending(error.config)
      store.commit('STOP_INDICATOR')
  
      if (!originalAxios.isCancel(error)) {
        return Promise.reject(error)
      }
      /*
      if (!error && !error.response) {
        // network error
        alert(`Unable to access API: ${error.message}`)
        // console.log(`Unable to access API: ${error.message}`)
      } else if (error.response.status == 401) {
        //      console.error('Unauthorized! Signing out...')
      }*/
      return Promise.reject(error)
    }
  )
}

requestInterceptor();
responseInterceptor();
